<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol
                :xl="6" :md="8" :sm="12">
                <FilterRole
                    allow-clear
                    v-model:value="state.params.role"
                    tag="sales-person"
                    style="width:100%;"/>
            </ACol>
            <ACol
                :xl="6" :md="8" :sm="12">
                <FilterGroupRole
                    allow-clear
                    v-model:value="state.params.group_role"
                    v-model:role="state.params.role"
                    tag="sales-person"
                    style="width:100%;"
                    placeholder="Pilih Group Area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterUsername
                    v-model:value="state.params.username"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterRegional
                    v-model:value="state.params.regional"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterArea
                    v-model:value="state.params.area"
                    v-model:region="state.params.regional"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ASelect
                    v-model:value="state.params.status"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AInput
                    allow-clear
                    placeholder="Cari ..."
                    v-model:value="state.params.q"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Data-Sales-Person"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import {
  FilterRegional,
  FilterArea,
  FilterRole,
  FilterUsername,
  FilterGroupRole,
} from '@/components/filter'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'
import {
    hasRoles,
    ROLE_TSO,
    ROLE_ASM,
    ROLE_SSM,
} from '@/helpers'

export default defineComponent({
    components: {
        DownloadExcel,
        FilterRegional,
        FilterArea,
        FilterRole,
        FilterUsername,
        FilterGroupRole,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Nomor Pegawai',
                    dataIndex: 'nomer_pegawai',
                },
                {
                    title: 'Nama Pengkap',
                    dataIndex: 'nama_lengkap',
                },
                {
                    title: 'Username',
                    dataIndex: 'username',
                },
                {
                    title: 'Role',
                    dataIndex: 'ROLE',
                },
                {
                    title: 'Group Area',
                    dataIndex: 'group_role',
                },
                {
                    title: 'Area',
                    dataIndex: 'cakupan_area',
                    customRender: ({ text }) => text.toString(),
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                    align: 'center',
                },
            ],
            endpoint: '/api/report/laporan-sales-person',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                regional: [],
                area: [],
                role: [],
                group_role: [],
                username: [],
                status: 1,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Tidak Aktif',
                    value: 0,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return {
                ...updateUrlWithQuery(params),
                status: state.params.status,
            }
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            errorMessage,
            handleTableChange,
            queryParams,
            // only variable role
            hasRoles,
            ROLE_TSO,
            ROLE_ASM,
            ROLE_SSM,
        }
    },
})
</script>
